import styled from "@mui/material/styles/styled";
import { borderRadius } from "@mui/system";
import { pxToRem } from "../../utils/pxToRem";

/**
 * @param {string} margin: margin of the content container
 */
export const ContentContainer = styled("div", {
  shouldForwardProp: (prop) =>
    !["margin", "backgroundColor", "padding"].includes(prop),
})(({ theme, margin, backgroundColor, padding }) => ({
  backgroundColor,
  padding,
  margin,
  paddingLeft: pxToRem(36),
  paddingRight: pxToRem(36),
  [theme.breakpoints.down("sm")]: {
    paddingLeft: pxToRem(22),
    paddingRight: pxToRem(22),
  },
}));

export const StoriesContainer = styled("div")(() => ({
  display: "flex",
  width: "100%",
  overflowY: "hidden",
  overflowX: "scroll",
  "&::-webkit-scrollbar": {
    // width: pxToRem(5),
    // height: pxToRem(5),
    display: "none",
  },
  // "&::-webkit-scrollbar-track": {
  //   background: "#fff",
  //   borderRadius: pxToRem(4),
  //   marginRight: pxToRem(10),
  // },
  // "&::-webkit-scrollbar-thumb": {
  //   background: "rgba(0,0,0,0.1)",
  //   borderRadius: pxToRem(4),
  // },
  // "&::-webkit-scrollbar-thumb:hover": {
  //   background: "rgba(0,0,0,0.2)",
  // },
}));

/**
 * @param {string} margin - margin of the container
 */
export const ImageCardContainer = styled("div", {
  shouldForwardProp: (prop) => !["margin"].includes(prop),
})(({ margin }) => ({
  display: "flex",
  justifyContent: "center",
  height: "100%",
  width: "100%",
  margin,
  position: "relative",
}));

export const DescriptionContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  // width: "fit-content",
  width: "100%",
  background: "rgb(0 0 0 / 45%)",
  height: pxToRem(60),
  bottom: "0px",
  textAlign: "center",
  borderRadius: `0 0 ${pxToRem(20)} ${pxToRem(20)}`,
  [theme.breakpoints.up("sm")]: {
    height: pxToRem(100),
  },
}));
