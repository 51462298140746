import { AppBar } from "@mui/material";
import { styled } from "@mui/material/styles";
import { pxToRem } from "../../utils/pxToRem";

export const AppBarSc = styled(AppBar, {
  shouldForwardProp: (prop) => !["display"].includes(prop),
})(({ theme, display }) => ({
  background: "#fff",
  color: "#000",
  display: display ?? "flex",
  width: "100%",
  padding: `${pxToRem(15)} ${pxToRem(10)}`,
  position: "fixed",
  top: "0",
  // zIndex: "1000",
  boxShadow:
    "0px 2px 4px -1px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 1%), 0px 1px 10px 0px rgb(0 0 0 / 2%)",
  height: "auto",
  overflow: "hidden",
  zIndex: "100",
  [theme.breakpoints.down("md")]: {
    padding: pxToRem(10),
    paddingBottom: pxToRem(1),
    // height: pxToRem(90),
  },
}));

export const LogoContainer = styled("div")(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: pxToRem(1),
  [theme.breakpoints.down("md")]: {
    paddingBottom: pxToRem(8),
  },
}));
export const NavOptionContainer = styled("div")(({ theme }) => ({
  display: "flex",
  overflow: "hidden",
  alignItems: "center",
  width: "100%",
  marginTop: pxToRem(10),
  [theme.breakpoints.down("lg")]: {
    paddingLeft: "0",
  },
  [theme.breakpoints.down("md")]: {
    overflowX: "auto",
    marginTop: "0",
    padding: "0",
  },
  "&::-webkit-scrollbar": {
    width: "0",
    background: "transparent",
  },
}));

export const MenuIconButton = styled("div")(({ theme }) => ({
  display: "none",
  padding: "0",
  marginRight: pxToRem(10),
  [theme.breakpoints.down("md")]: {
    display: "block",
    marginRight: pxToRem(0),
  },
}));
