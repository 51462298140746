import React from "react";

export default class AdComponent2 extends React.Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin:"20px 0"
        }}
      >
        <ins
          class="adsbygoogle"
          style={{
            display: "inline-block",
            width: "300px",
            height: "250px",
          }}
          data-ad-client="ca-pub-9365813185370392"
          data-ad-slot="6179285565"
          data-full-width-responsive="false"
          // data-adtest="on"
        ></ins>
      </div>
    );
  }
}
