import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { pxToRem } from "../../../utils/pxToRem";
function ArticleCardLoading() {
  return (
    <>
      <Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        height={300}
      />
      <Box sx={{ padding: pxToRem(10) }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="10%"
            height={10}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="10%"
            height={10}
          />
        </Box>
        <Skeleton
          sx={{ marginTop: pxToRem(10) }}
          animation="wave"
          variant="rectangular"
          width="100%"
          height={30}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: pxToRem(10),
          }}
        >
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="30%"
            height={20}
            sx={{ borderRadius: pxToRem(5) }}
          />
        </Box>
      </Box>
    </>
  );
}

export default ArticleCardLoading;
