import styled from "@mui/material/styles/styled";
import { pxToRem } from "../../utils/pxToRem";

export const ImageContainer = styled("div")(({ theme }) => ({
  // filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
  cursor: "pointer",
  [theme.breakpoints.up("sm")]: {
    width: pxToRem(377),
    height: pxToRem(446),
  },
  [theme.breakpoints.down("sm")]: {
    width: pxToRem(182),
    height: pxToRem(259),
  },
}));

