import { styled } from "@mui/material/styles";
import { CardMedia } from "@mui/material";
import { pxToRem } from "../../utils/pxToRem";

export const CardContent = styled("div")(({ theme }) => ({
  padding: "0px",
  overflow: "hidden",
}));

export const CardSc = styled("div")(({ theme }) => ({
  boxShadow: "0px -6px 51px rgba(0, 0, 0, 0.11)",
  backgroundColor: "#fff",
  height: pxToRem(450),
  cursor: "pointer",
  borderRadius: "10px",
  borderTopRightRadius: pxToRem(10),
  borderTopLeftRadius: pxToRem(10),
  overflow: "hidden",
  [theme.breakpoints.down("sm")]: {
    height: "100%",
    paddingBottom: "0",
  },
}));

export const CardMediaContainer = styled("div")(({ theme }) => ({
  position: "relative",
}));
export const CardHeader = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));
export const CardMediaSc = styled(CardMedia)(({ theme }) => ({
  position: "relative",
  objectFit: "fit",
  overflow: "hidden",
}));

export const TypeLogo = styled("img")(({ theme }) => ({
  position: "absolute",
  top: "14px",
  right: "10px",
  width: "40px",
  height: "40px",
}));
