import styled from "@mui/material/styles/styled";
import { pxToRem } from "../../utils/pxToRem";

export const ContentContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-around",
  alignItems: 'center',
  width: "100%",
  height: "100%",
  cursor: "pointer"
}));

export const TextContainer = styled("div")(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  paddingLeft: pxToRem(60),
  [theme.breakpoints.down("sm")]: {
    paddingLeft: pxToRem(10)
  },
}));

export const ImageContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    width: pxToRem(300),
    height: pxToRem(223),
  },
  [theme.breakpoints.down("sm")]: {
    width: pxToRem(120),
    height: pxToRem(90),
  },
}));
