import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import NavbarButton from "../NavbarButtons";
import { pxToRem } from "../../../utils/pxToRem";
import Logo from "../../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";

export default function SwipeableTemporaryDrawer({
  state,
  toggleDrawer,
  navbarOptions,
}) {
  let navigate = useNavigate();

  const redirectToHome = () => {
    navigate("/");
  };
  const list = () => (
    <Box
      sx={{ width: "right" === "top" || "right" === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem>
          <img
            src={Logo}
            alt="Logo"
            width={"90px"}
            onClick={() => redirectToHome()}
          />
        </ListItem>
        <ListItem button sx={{ padding: `${pxToRem(5)} ${pxToRem(20)}` }}>
          <NavbarButton name={"Home"} index={1} />
        </ListItem>
        {navbarOptions.map((element, index) => (
          <ListItem
            button
            key={index + 1}
            sx={{ padding: `${pxToRem(10)} ${pxToRem(20)}` }}
          >
            <NavbarButton name={element.name} key={element.name} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <React.Fragment>
      <SwipeableDrawer
        open={state["right"]}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {list()}
      </SwipeableDrawer>
    </React.Fragment>
  );
}
