import { styled } from "@mui/material/styles";
import { pxToRem } from "../../../utils/pxToRem";

export const NavbarButtonSc = styled("div")(({ theme }) => ({
  fontFamily: "Roboto",
  fontWeight: "500",
  fontSize: pxToRem(22),
  textTransform: "capitalize",
  color: "#362A43",
  display: "flex",
  cursor: "pointer",
  marginRight: pxToRem(30),
  [theme.breakpoints.down("sm")]: {
    fontSize: pxToRem(14),
    marginRight: pxToRem(12),
    paddingTop: `${pxToRem(4)}`,
    paddingBottom: `${pxToRem(0)}`,
  },
}));
