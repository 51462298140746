import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import useMediaQuery from "@mui/material/useMediaQuery";
import MyStories from "react-my-stories";
import { ReactComponent as Loader } from "../../assets/puff.svg";
import { pxToRem } from "../../utils/pxToRem";
import refreshRate from "refresh-rate";

export default function AllStories() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const { id } = useParams();
  const [storyData, setStoryData] = useState([{ url: "", type: "img" }]);
  const location = useLocation();
  const [notIn, setNotIn] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [stop, setStop] = useState(false);
  const [previousLocation, _] = useState(
    location.state?.previousLocation ? location.state.previousLocation : "/"
  );
  const [showAlert, setShowAlert] = useState(false);
  const [rLoading, setRLoading] = useState(true);
  const [rRate, setRRate] = useState(60);

  const updateRefreshRate = async () => {
    const fps = await refreshRate({ sampleCount: 140 });
    setRRate(fps);
    setRLoading(false);
  };

  const handleStoryData = (data) => {
    if (!data) {
      setStop(true);
      setShowAlert(true);
      return 1;
    }
    if (data.storyItems && data.storyItems.length === 0) {
      const story = [
        {
          url: "https://api.vistory.in" + data.mainImage,
          type: "img",
        },
      ];
      setStoryData(story);
      return;
    }
    if (data.storyItems && data.storyItems.length > 0) {
      const story = data.storyItems.map((item) => {
        return {
          url: "https://api.vistory.in" + item.path,
          type: item.type === "image" ? "img" : "video",
          description: item.description,
        };
      });
      setStoryData(story);
      return;
    }
  };

  const getStoryData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        "https://api.vistory.in/vistory/api/v1/stories/getStoryByHash",
        {
          headers: {
            "DEVICE-TYPE": isMobile ? "Android" : "Web",
            VER: "1",
          },
          params: {
            storyId: id,
          },
        }
      );
      setNotIn(id);
      handleStoryData(res.data.responseObject);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const storyPaginate = async (prevPage, prevAction) => {
    try {
      setLoading(true);
      const res = await axios.post(
        "https://api.vistory.in/vistory/api/v1/stories/getDetailedStories",
        [
          {
            key: "STORY_NOT_IN",
            value: notIn,
          },
          // {
          //   key: "CATEGORY",
          //   value: location.state.category ? location.state.category : "",
          // },
        ],
        {
          headers: {
            "DEVICE-TYPE": isMobile ? "Android" : "Web",
            VER: "1",
          },
          params: {
            direction: "ASC",
            pageNumber: prevAction ? prevPage : page,
            pageSize: 1,
          },
        }
      );
      const incPage = handleStoryData(res.data.responseObject.content[0]);
      setLoading(false);
      if (incPage !== 1) setPage((prev) => prev + 1);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const handlePrevious = () => {
    if (stop) setStop(false);
    let currentPage = page - 1;
    if (currentPage === 0) {
      setPage(0);
      getStoryData();
      return;
    }

    if (currentPage > 0) {
      setPage(currentPage - 1);
      storyPaginate(currentPage - 1, true);
    }
  };

  const closeAlert = () => {
    setShowAlert(false);
    navigate(previousLocation, {
      state: {
        storyClosed: true,
      },
    });
  };

  useEffect(() => {
    updateRefreshRate();
  }, []);

  useEffect(() => {
    getStoryData();
  }, [location.pathname]);

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        position: "absolute",
      }}
    >
      {loading || rLoading ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "black",
          }}
        >
          <Loader />
        </div>
      ) : (
        <MyStories
          stories={storyData}
          closeCallback={() => {
            navigate(previousLocation, {
              state: {
                storyClosed: true,
              },
            });
          }}
          nextCallback={() => {
            if (!stop) storyPaginate();
          }}
          previousCallback={() => {
            handlePrevious();
          }}
          interval={4000}
          bottomTextStyle={{
            fontFamily: "SW721CBold",
            fontSize: `${pxToRem(20)}`,
          }}
          refreshRate={rRate}
        />
      )}
      <Snackbar
        open={showAlert}
        autoHideDuration={2500}
        onClose={closeAlert}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert onClose={closeAlert} severity="info" sx={{ width: "100%" }}>
          You have reached the end of stories!
        </Alert>
      </Snackbar>
    </div>
  );
}
