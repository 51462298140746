export const storePreviousArticlesId = (id, setViewedArticleIds) => {
    if (localStorage.getItem('articleIds') != null) {
        let ids = JSON.parse(localStorage.getItem("articleIds"));
        if (!ids.includes(id)) {
            if (id != "" && id != null && id.length != 0) {
                ids.push(id);
            }
            localStorage.setItem('articleIds', JSON.stringify(ids));
        }
        setViewedArticleIds(ids);
    } else {
        if (id) {
            localStorage.setItem('articleIds', JSON.stringify([id]));
        }
        setViewedArticleIds([id]);
    }
}