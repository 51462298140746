import * as React from "react";
import CardContent from "@mui/material/CardContent";
import { Button, IconButton } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import audio from "../../assets/icons/audio.svg";
import video from "../../assets/icons/video.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import {
  CardHeader,
  CardMediaContainer,
  CardMediaSc,
  CardSc,
  TypeLogo,
} from "./style";
import { PoppinsGenericSC } from "../Texts";
import { pxToRem } from "../../utils/pxToRem";
import { baseUrl } from "../../utils/constants";
import { Helmet } from "react-helmet";

// import { Linking } from "react-native";
const ArticleCard = ({
  urlHash,
  type,
  category,
  audioFile,
  imageUrl,
  title,
  description,
  audioHandler,
  logo,
  publisherName,
}) => {
  const location = useLocation();
  let navigate = useNavigate();
  const clickHandler = (urlHash, title) => {
    if (location.pathname.includes("minus-one")) {
      const finalRedirectUrl = new URL(
        `/minus-one/${urlHash}/${title}`,
        window.location.origin
      );
      window.location.href = finalRedirectUrl.toString();
    } else {
      navigate(`/${category}/detail/${urlHash}`, { state: { category } });
    }
  };

  const shareButtonHandler = (image) => {
    if (location.pathname.includes("minus-one")) {
      // if (window.Android && typeof window.Android.shareContent === "function") {
      window.Android.share(`/${category}/detail/${urlHash}`);
      // } else {
      //   console.error("Sharing not supported in this environment");
      // }
    } else {
      navigator.share({
        title: title,
        url: `/${category}/detail/${urlHash}`,
        text: `${description}`,
      });
    }
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    const link = document.createElement("a");
    link.href = `/${category}/detail/${urlHash}`;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    link.click();
  };
  const handleCtrlClick = (event) => {
    if (event.ctrlKey) {
      event.preventDefault(); // Prevent default navigation
      handleContextMenu(event); // Open in new tab
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const CardTitleStyle = isMobile
    ? {
        fontFamily: "roboto",
        fontSize: pxToRem(20),
        fontWeight: "bold",
        lineHeight: "135%",
      }
    : {
        fontFamily: "roboto",
        fontSize: pxToRem(21),
        fontWeight: "bold",
        lineHeight: "134%",
      };

  const CardDescriptionStyle = isMobile
    ? {
        fontFamily: "Roboto",
        fontSize: pxToRem(15),
        fontWeight: "400",
        lineHeight: "134%",
      }
    : {
        fontSize: pxToRem(16),
        fontWeight: "400",
        lineHeight: "134%",
      };
  const CategoryStyle = isMobile
    ? { fontSize: "12px", fontWeight: "500" }
    : { fontSize: "14px", fontWeight: "500" };
  return (
    <CardSc onContextMenu={handleContextMenu} onMouseDown={handleCtrlClick}>
      <Helmet>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${category}/detail/${urlHash}`} />
      </Helmet>
      <CardContent sx={{ padding: "0!important" }}>
        <CardMediaContainer
          sx={{ padding: "0" }}
          onClick={() => clickHandler(urlHash, title)}
        >
          {imageUrl.includes("https://vistory.s3.ap-south-1.amazonaws.com/") ? (
            <CardMediaSc
              component="img"
              height="250px"
              image={imageUrl}
              alt=""
            />
          ) : (
            <CardMediaSc
              component="img"
              height="250px"
              image={baseUrl + imageUrl}
              alt=""
            />
          )}
          {type === "audio" || type === "video" ? (
            <TypeLogo
              src={type === "audio" ? audio : type === "video" ? video : ""}
            />
          ) : (
            ""
          )}
        </CardMediaContainer>
        <CardContent sx={{ padding: "4px 15px" }}>
          <CardHeader>
            <PoppinsGenericSC {...CategoryStyle}>{category}</PoppinsGenericSC>
            {!location.pathname.includes("minus-one") && (
              <IconButton onClick={() => shareButtonHandler(imageUrl)}>
                <ShareIcon />
              </IconButton>
            )}
          </CardHeader>
          <PoppinsGenericSC
            {...CardTitleStyle}
            onClick={() => clickHandler(urlHash)}
          >
            {title}
          </PoppinsGenericSC>
          {category === "AudPod" ? (
            <AudioPlayer
              style={{ marginTop: pxToRem(10) }}
              src={audioFile ? baseUrl + audioFile : ""}
              onPlay={(e) => {
                audioHandler(e);
              }}
            />
          ) : (
            <PoppinsGenericSC
              {...CardDescriptionStyle}
              onClick={() => clickHandler(urlHash, title)}
            >
              {description}
            </PoppinsGenericSC>
          )}
        </CardContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            padding: "0 0 15px 15px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: pxToRem(15),
            }}
          >
            <img src={baseUrl + logo} alt="" height={"20px"} />
            <span
              style={{
                fontSize: "14px",
                paddingLeft: "5px",
                fontWeight: 700,
                textTransform: "capitalize",
              }}
            >
              {publisherName}
            </span>
          </div>

          <Button
            onClick={() => clickHandler(urlHash)}
            size="small"
            sx={{
              fontFamily: "Roboto",
              textTransform: "capitalize",
              color: "black",
              fontWeight: "bold",
              marginRight: pxToRem(25),
              marginTop: pxToRem(15),
            }}
          >
            {type === "audio" ? "Listen" : "Read more"}
            <ArrowRightAltIcon />
          </Button>
        </div>
      </CardContent>
    </CardSc>
  );
};
export default ArticleCard;
