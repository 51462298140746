import styled from "@mui/material/styles/styled";

/**
 * @param {string} fontStyle - font-style
 * @param {string} fontSize - font size of the text
 * @param {string} fontWeight - font weight of the text
 * @param {string} lineHeight - line height of the text
 * @param {string} color - color of the text
 * @param {string} margin - margin of the text
 * @param {string} textAlign - text align of the text
 * @param {string} textTransform - text transform of the text
 * @param {string} letterSpacing - letter spacing of the text
 * @param {string} lineClamp - number of line to display after that elipsis will be added
 */
export const PoppinsGenericSC = styled("div", {
  shouldForwardProp: (prop) =>
    ![
      "fontFamily",
      "fontStyle",
      "fontSize",
      "fontWeight",
      "lineHeight",
      "color",
      "margin",
      "textAlign",
      "textTransform",
      "letterSpacing",
      "lineClamp",
    ].includes(prop),
})(
  ({
    fontFamily,
    fontStyle,
    fontSize,
    fontWeight,
    lineHeight,
    color,
    margin,
    textAlign,
    letterSpacing,
    textTransform,
    lineClamp,
  }) => ({
    fontFamily: fontFamily??"Poppins, sans-serif",
    fontSize: fontSize ?? "12px",
    fontWeight: fontWeight ?? "normal",
    fontStyle: fontStyle ?? "normal",
    color: color ?? "#000000",
    lineHeight: lineHeight,
    textAlign,
    margin,
    textTransform: textTransform ?? "initial",
    letterSpacing: letterSpacing ?? "initial",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: lineClamp ?? 2,
    WebkitBoxOrient: "vertical",
  })
);
