import styled from "@mui/material/styles/styled";
import { pxToRem } from "../../../utils/pxToRem";

/**
 * @param {string} margin: margin of the content container
 */
export const ContentContainer = styled("div", {
  shouldForwardProp: (prop) => !["margin"].includes(prop),
})(({ theme, margin }) => ({
  margin,
  paddingLeft: pxToRem(36),
  paddingRight: pxToRem(36),
  [theme.breakpoints.down("sm")]: {
    paddingLeft: pxToRem(22),
    paddingRight: pxToRem(22),
  },
}));
