import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { pxToRem } from "../../../utils/pxToRem";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function LoadingTrendingCard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-evenly",
      }}
    >
      <Skeleton
        width={isMobile ? "35%" : "20%"}
        height={isMobile ? 150 : 200}
      />
      <Box sx={{ width: pxToRem(400), paddingLeft: pxToRem(20) }}>
        <Skeleton width={"20%"} height={pxToRem(30)} />
        <Skeleton width={"90%"} height={pxToRem(50)} />
      </Box>
    </Box>
  );
}

export default LoadingTrendingCard;
