import React from 'react'

export default function Footer() {

    const footerStyle = {
        width: "100% !important",
        display: 'flex',
        justifyContent: "center",
        backgroundColor: "#3A3740",
        color: "#ffffff",
        padding: "10px",
        marginTop: "20px"
    };
    return (
        <div style={footerStyle}>
            <span>&#169; vistory 2024</span>
        </div>
    )
}
