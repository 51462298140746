import { Container, IconButton } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { CardHeader } from "../../components/Card/style";
import ShareIcon from "@mui/icons-material/Share";
import { DataContent } from "./style";
import axios from "axios";
import { styled } from "@mui/system";
import { pxToRem } from "../../utils/pxToRem";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import Skeleton from "@mui/material/Skeleton";
import ArticleCardLoading from "../../components/Loading/ArticleDetail";
import Grid from "@mui/material/Grid";
import ArticleCard from "../../components/Card";
import { useInView } from "react-intersection-observer";
import LoadingCard from "../../components/Loading/Card";
import { storePreviousArticlesId } from "../../utils/function";
import Footer from "../../components/Footer";
import { baseUrl } from "../../utils/constants";
import AdComponent from "../../components/Ads/Adcomponent";
import ReactDOMServer from "react-dom/server";

const DetailTab = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let { category } = useParams();
  const [datas, setDatas] = useState([]);
  const [articleLoading, setArticleLoading] = useState(true);
  let { id } = useParams();
  const [showArticleLower, setShowArticleLower] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [paginateArticleList, setPaginateArticleList] = useState([]);
  const { ref, inView } = useInView();
  const [isPaginating, setIsPaginating] = useState(false);
  const [viewedArticleIds, setViewedArticleIds] = useState(
    localStorage.getItem("articleIds")
      ? JSON.parse(localStorage.getItem("articleIds"))
      : []
  );
  // last isLastArticlePage
  const [isLastArticlePage, setIsLastArticlePage] = useState(false);
  const [article, setArticle] = useState({});
  // SingleArticleLoading
  const [singleArticleLoading, setSingleArticleLoading] = useState(true);
  const gridContainerStyle = {
    padding: { xs: pxToRem(10), md: `${pxToRem(20)} ${pxToRem(40)}` },
    backgroundColor:
      "background: linear-gradient(180deg, rgba(255, 255, 255, 0) -9.97%, #ECF0F7 100%)",
  };
  const adComponentHTML = ReactDOMServer.renderToStaticMarkup(
    <AdComponent slot="5897306504" />
    // <AdComponent slot="3006566352" />
  );
  const processHTMLContent = (htmlBody) => {
    const content = htmlBody;
    const splitContent = content.split(/(<p[^>]*>.*?<\/p>)/g);
    let modifiedContent = "";
    let strongTagCount = 0;
    for (let i = 0; i < splitContent.length; i++) {
      if (splitContent[i].startsWith("<p")) {
        strongTagCount++;
        modifiedContent += splitContent[i];
        if (strongTagCount % 5 === 0) {
          modifiedContent += adComponentHTML;
        }
      } else {
        modifiedContent += splitContent[i];
      }
    }

    return { __html: modifiedContent };
  };

  const getSingleArticle = async () => {
    try {
      setSingleArticleLoading(true);
      let response = await axios.get(
        `${baseUrl}/vistory/api/v1/articles/getArticleByHash`,
        {
          headers: {
            "DEVICE-TYPE": isMobile ? "Android" : "Web",
            VER: "1",
          },
          params: {
            articleId: id,
          },
        }
      );
      setArticle(response.data.responseObject);
      setSingleArticleLoading(false);
    } catch (error) {
      console.error(error);
      setSingleArticleLoading(false);
    }
  };
  const getArticle = async () => {
    try {
      setArticleLoading(true);
      let response = await axios.post(
        `${baseUrl}/vistory/api/v1/articles/getDetailedArticles`,
        [
          {
            key: "CATEGORY",
            value: category === "Home" ? null : category,
          },
          {
            key: "ARTICLE_NOT_IN",
            value:
              viewedArticleIds.toString() != ""
                ? viewedArticleIds.toString()
                : id,
          },
        ],
        {
          headers: {
            "DEVICE-TYPE": isMobile ? "Android" : "Web",
            VER: "1",
          },
          params: {
            direction: "DESC",
            pageNumber: 0,
            pageSize: 4,
          },
        }
      );
      setDatas(response.data.responseObject.content);
      setArticleLoading(false);
    } catch (error) {
      setArticleLoading(false);
    }
  };

  const getArticleList = async (pageNumber, paginate) => {
    try {
      if (paginate) setIsPaginating(true);
      const body = [
        {
          key: "CATEGORY",
          value: category === "Home" ? null : category,
        },
        {
          key: "ARTICLE_NOT_IN",
          value:
            viewedArticleIds.toString() != ""
              ? viewedArticleIds.toString()
              : id,
        },
      ];
      const res = await axios.post(
        `${baseUrl}/vistory/api/v1/articles/getArticles`,
        body,
        {
          headers: {
            "DEVICE-TYPE": isMobile ? "Android" : "Web",
            VER: "1",
          },
          params: {
            direction: "DESC",
            pageNumber: paginate && pageNumber > 0 ? pageNumber : 0,
            pageSize: 6,
          },
        }
      );
      if (paginate) {
        setPaginateArticleList((prev) => [
          ...prev,
          ...res.data.responseObject.content,
        ]);
        if (res.data.responseObject.content.length > 0)
          setPageNumber(pageNumber + 1);
        setIsPaginating(false);
        return;
      }
      setPageNumber(1);
      setIsLastArticlePage(!res.data.responseObject.empty);
      setPaginateArticleList([]);
    } catch (err) {
      setIsPaginating(false);
    }
  };
  useEffect(() => {
    storePreviousArticlesId(id, setViewedArticleIds);
    getArticleList(0, false);
  }, [category]);

  useEffect(() => {
    storePreviousArticlesId(id, setViewedArticleIds);
    if (inView) getArticleList(pageNumber, true);
  }, [inView, category]);

  useEffect(() => {
    setShowArticleLower(true);
    storePreviousArticlesId(id, setViewedArticleIds);
    getSingleArticle();
    getArticle();
  }, [category, id]);
  const CardTitleStyle = isMobile
    ? {
        fontFamily: "Montserrat",
        fontSize: pxToRem(25),
        fontWeight: "800",
        margin: 0,
        padding: 0,
      }
    : {
        fontFamily: "Montserrat",
        fontSize: pxToRem(40),
        fontWeight: "800",
        margin: 0,
        padding: 0,
      };

  const shareButtonHandler = (data) => {
    navigator.share({
      title: data.title,
      url: `/${category}/detail/${data.urlHash}`,
      text: data.description,
    });
  };

  const Description = styled("div")(({ theme }) => ({
    position: "relative",
    opacity: "1",
    display: showArticleLower ? "none" : "block",
    "&::after": {
      content: '""',
      width: "100%",
      height: pxToRem(40),
      display: "inline-block",
      fontSize: 24,
      backgroundColor: "#fff",
      opacity: showArticleLower ? "0" : "",
      boxShadow: showArticleLower ? "none" : "1px 2px 50px 47px #fff",
    },
  }));

  const ShowButton = styled("div")(({ theme }) => ({
    zIndex: "1",
    position: "absolute",
    top: "50%",
    left: "45%",
    opacity: "1",
    backgroundColor: "#F3854D",
    color: "#fff",
    padding: "10px 15px",
    borderRadius: "20px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      left: "35%",
      top: "78%",
    },
  }));
  return (
    <>
      {singleArticleLoading ? (
        <ArticleCardLoading />
      ) : (
        <div style={{ marginTop: pxToRem(60) }}>
          <Container maxWidth="lg">
            <CardHeader style={{ paddingTop: "10px" }}>
              <h1 style={CardTitleStyle}>{article.title}</h1>
            </CardHeader>
            <CardHeader>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={baseUrl + article.publisherLogo}
                  alt=""
                  height={isMobile ? "25px" : "30px"}
                />
                <span
                  style={{
                    fontSize: "14px",
                    paddingLeft: "5px",
                    fontWeight: 700,
                    textTransform: "capitalize",
                  }}
                >
                  {article.publisherName}
                </span>
              </div>
              <IconButton onClick={() => shareButtonHandler(article)}>
                <div style={{ width: "15px" }}>
                  <ShareIcon />
                </div>
              </IconButton>
            </CardHeader>

            <div>
              {article.category === "AudPod" ? (
                <AudioPlayer
                  style={{ paddingTop: pxToRem(10) }}
                  autoPlay
                  src={article.audioFile ? baseUrl + article.audioFile : ""}
                />
              ) : (
                !showArticleLower && (
                  <Description>
                    {article.description}
                    <ShowButton onClick={() => setShowArticleLower(true)}>
                      Read More
                    </ShowButton>
                  </Description>
                )
              )}
            </div>

            {showArticleLower && (
              <DataContent
                dangerouslySetInnerHTML={processHTMLContent(
                  article.contentBody
                )}
              ></DataContent>
            )}
          </Container>
        </div>
      )}
      {/* multiple articles expanded */}
      {articleLoading ? (
        <ArticleCardLoading />
      ) : (
        datas.map((data, index) => (
          <div style={{ marginTop: pxToRem(20) }} key={index}>
            {
              <div style={{ padding: "20px" }}>
                <hr style={{ backgroundColor: "#a6afbd" }} />
              </div>
            }
            <Container maxWidth="lg">
              <CardHeader style={{ paddingTop: "10px" }}>
                <h1 style={CardTitleStyle}>{data.title}</h1>
              </CardHeader>
              <CardHeader>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  <img
                    src={baseUrl + data.publisherLogo}
                    alt=""
                    height={isMobile ? "20px" : "30px"}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      paddingLeft: "5px",
                      fontWeight: 700,
                      textTransform: "capitalize",
                    }}
                  >
                    {data.publisherName}
                  </span>
                </div>
                <IconButton onClick={() => shareButtonHandler(data)}>
                  <div style={{ width: "15px" }}>
                    <ShareIcon />
                  </div>
                </IconButton>
              </CardHeader>

              <div>
                {data.category === "AudPod" ? (
                  <AudioPlayer
                    style={{ paddingTop: pxToRem(10) }}
                    autoPlay
                    src={data.audioFile ? baseUrl + data.audioFile : ""}
                  />
                ) : (
                  !showArticleLower && (
                    <Description>
                      {data.description}
                      <ShowButton onClick={() => setShowArticleLower(true)}>
                        Read More
                      </ShowButton>
                    </Description>
                  )
                )}
              </div>

              {showArticleLower && (
                <DataContent
                  dangerouslySetInnerHTML=
                  // {{ __html: data.contentBody }}
                  {processHTMLContent(data.contentBody)}
                ></DataContent>
              )}
            </Container>
          </div>
        ))
      )}
      <div ref={paginateArticleList.length === 0 ? ref : null}></div>
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="start"
        alignItems="center"
        sx={gridContainerStyle}
      >
        <AdComponent slot="3006566352 " />
        {paginateArticleList.map((element, idx) => {
          const last = paginateArticleList.length - 1;
          return (
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              key={`paginate-card-container-${idx}`}
              ref={last === idx && paginateArticleList.length > 0 ? ref : null}
            >
              <ArticleCard
                urlHash={element.urlHash}
                title={element.title}
                description={element.description}
                type={element.type}
                category={element.category}
                imageUrl={element.mainImage}
                key={`paginate-card-${idx}`}
                logo={element.publisherLogo}
                publisherName={element.publisherName}
              />
            </Grid>
          );
        })}
        {isPaginating &&
          Array.from(isMobile ? new Array(1) : new Array(3)).map(
            (el, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  key={"paginate-loading" + index}
                >
                  <LoadingCard />
                </Grid>
              );
            }
          )}
      </Grid>
      <AdComponent slot="3006566352 " />
      {isLastArticlePage && <Footer />}
    </>
  );
};

export default DetailTab;
