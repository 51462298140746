import React from "react";

export default class AdComponent extends React.Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render() {
    const { slot } = this.props;
    return (
      <ins
        className="adsbygoogle"
        style={{ display: "inline-block", width: "300px", height: "250px" }}
        data-ad-client="ca-pub-9365813185370392"
        data-ad-slot={slot}
        data-adtest="on"
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
    );
  }
}
