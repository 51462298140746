import React, { useEffect, useRef, useState } from "react";
import { Container, Grid } from "@mui/material";
import axios from "axios";
import ArticleCard from "../../components/Card";
import CustomCarousel from "../../components/Carousel";
import Trending from "./Trending";
import Stories from "../Stories";
import { pxToRem } from "../../utils/pxToRem";
import { useParams, useLocation } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import LoadingCard from "../../components/Loading/Card";
import { storePreviousArticlesId } from "../../utils/function";
import Footer from "../../components/Footer";
import AdComponent from "../../components/Ads/Adcomponent";
import { MgidWidget } from "react-mgid-widget";
import AdSense from "react-adsense";
import AdComponent2 from "../../components/Ads/adComponent2";
import MGIDBody from "../../components/Ads/Mgidbody";
import MGIDScriptComponent from "../../components/Ads/Mgidtop";

const ArticleList = () => {
  let { category } = useParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isPaginating, setIsPaginating] = useState(false);
  const [articleList, setArticleList] = useState([]);
  const [paginateArticleList, setPaginateArticleList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [playingAudio, setPlayingAudio] = useState("");
  const [scrolledToStories, setScrolledToStories] = useState(false);
  const [viewedArticleIds, setViewedArticleIds] = useState([]);
  const { ref, inView } = useInView();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLastArticlePage, setIsLastArticlePage] = useState(false);

  const gridContainerStyle = {
    padding: { xs: pxToRem(10), md: `${pxToRem(20)} ${pxToRem(40)}` },
    backgroundColor:
      "background: linear-gradient(180deg, rgba(255, 255, 255, 0) -9.97%, #ECF0F7 100%)",
  };

  const marginStyle = isMobile ? `${pxToRem(0)} 0 ` : `${pxToRem(30)} 0 `;
  const marginTrendingStyle = isMobile
    ? `${pxToRem(10)} 0 `
    : `${pxToRem(30)} 0 `;

  const storiesRef = useRef(null);

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day} 11:59:59`;
  }
  const getArticleList = async (pageNumber, paginate) => {

    try {

      const currentDate = new Date();

      const body = [
        {
          key: "CATEGORY",
          value: category === "Home" ? null : category,
        },
        {
          dateTimeValue: formatDate(currentDate),
          key: "PUBLISH_DATE_FROM"
        },
        {
          key: "STATUS",
          value: 1,
        },
        // {
        //   key: "ARTICLE_NOT_IN",
        //   value: viewedArticleIds.toString(),
        // },
      ];
      if (paginate) setIsPaginating(true);
      if (!paginate) setIsLoading(true);
      const res = await axios.post(
        "https://api.vistory.in/vistory/api/v1/articles/getArticles",
        body,
        {
          headers: {
            "DEVICE-TYPE": isMobile ? "Android" : "Web",
            VER: "1",
          },
          params: {
            direction: "DESC",
            pageNumber: paginate && pageNumber > 0 ? pageNumber : 0,
            pageSize: 6,
            sortingColumn: "publishDate",
            suffle: true,
          },
        }
      );
      if (paginate) {
        if (location.pathname.includes("minus-one")) {
          setPaginateArticleList((prev) => [
            ...prev,
            ...res.data.responseObject.content.filter(item => item.category === "Travel" || item.category === "Entertainment"),
          ]);
        } else {
          setPaginateArticleList((prev) => [
            ...prev,
            ...res.data.responseObject.content,
          ]);
        }
        if (res.data.responseObject.content.length > 0)
          setPageNumber(pageNumber + 1);
        setIsPaginating(false);
        return;
      }
      setPageNumber(1);
      setPaginateArticleList([]);
      setArticleList(res.data.responseObject.content);
      setIsLastArticlePage(!res.data.responseObject.empty);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      setIsPaginating(false);
    }
  };
  useEffect(() => {
    storePreviousArticlesId("", setViewedArticleIds);
  }, []);
  useEffect(() => {
    storePreviousArticlesId("", setViewedArticleIds);
    if (inView) getArticleList(pageNumber, true);
  }, [inView, category]);

  useEffect(() => {
    storePreviousArticlesId("", setViewedArticleIds);
    getArticleList(0, false);
  }, [category]);

  const getPlayingAudioElement = (event) => {
    if (playingAudio) {
      playingAudio.pause();
    }
    setPlayingAudio(event.currentTarget);
  };
  useEffect(() => {
    if (
      storiesRef.current &&
      location.state?.storyClosed &&
      !isLoading &&
      !isPaginating &&
      !scrolledToStories
    ) {
      storiesRef.current.scrollIntoView({ behavior: "smooth" });
      setScrolledToStories(true);
    }
  }, [storiesRef, location, isLoading, isPaginating]);

  return (
    <>
      {/* <CustomCarousel /> */}
      {!location.pathname.includes("minus-one") && (
        <div style={{ marginTop: "100px" }}>
          <AdComponent2 />
        </div>
      )}

      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="start"
        alignItems="center"
        sx={gridContainerStyle}
      >
        {isLoading &&
          Array.from(new Array(6)).map((el, index) => {
            return (
              <Grid item xs={12} sm={12} md={4} key={"loading" + index}>
                <LoadingCard />
              </Grid>
            );
          })}
        {!isLoading &&
          articleList.map((element, idx) => {
            const last = articleList.length - 1;
            const isThirdElement = (idx + 1) % 3 === 0;
            return (
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                key={`card-container-${idx}`}
                ref={
                  last === idx && paginateArticleList.length === 0 ? ref : null
                }
              >
                {/* <AdComponent slot='3006566352 '/> */}
                <ArticleCard
                  urlHash={element.urlHash}
                  title={element.title}
                  description={element.description}
                  type={element.type}
                  category={element.category}
                  imageUrl={element.mainImage}
                  audioFile={element.audioFile ? element.audioFile : null}
                  key={`card-${idx}`}
                  audioHandler={getPlayingAudioElement}
                  logo={element.publisherLogo}
                  publisherName={element.publisherName}
                />
                {/* <AdComponent slot='3006566352 '/> */}
                {idx !== last && (
                  <Grid item xs={12} sm={12} md={4} key={`ad-container-${idx}`}>
                    {isThirdElement && location.pathname.includes("minus-one") && (<MgidWidget
                      id="M782567ScriptRootC1590357"
                      src="https://jsc.mgid.com/v/i/vistory.mobi.1590357.js"
                    />)}
                    {/* <AdComponent2 /> */}
                    {/* <MGIDScriptComponent /> */}
                  </Grid>
                )}
              </Grid>
            );
          })}
      </Grid>
      {/* <MGIDBody /> */}
      {/* <AdComponent2 /> */}

      {/* ---trending component here--- */}
      {/* <Trending margin={marginStyle} /> */}
      {/* ---stories component here--- */}
      {/* <div ref={storiesRef}>
        <Stories margin={marginTrendingStyle} />
      </div> */}

      {/* ---pagination component here--- */}
      {/* <AdComponent slot='3006566352 '/> */}
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="start"
        alignItems="center"
        sx={gridContainerStyle}
      >
        {paginateArticleList.map((element, idx) => {
          const last = paginateArticleList.length - 1;
          const isThirdElement = (idx + 1) % 3 === 0;
          return (
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              key={`paginate-card-container-${idx}`}
              ref={last === idx && paginateArticleList.length > 0 ? ref : null}
            >
              {/* <AdComponent slot='3006566352 '/> */}
              <ArticleCard
                urlHash={element.urlHash}
                title={element.title}
                description={element.description}
                type={element.type}
                category={element.category}
                imageUrl={element.mainImage}
                key={`paginate-card-${idx}`}
                logo={element.publisherLogo}
                publisherName={element.publisherName}
              />
              {/* <AdComponent slot='3006566352 '/> */}
              {idx !== last && (
                <Grid item xs={12} sm={12} md={4} key={`ad-container-${idx}`}>
                  {isThirdElement && location.pathname.includes("minus-one") && (<MgidWidget
                    id="M782567ScriptRootC1590357"
                    src="https://jsc.mgid.com/v/i/vistory.mobi.1590357.js"
                  />)}
                  {/* <MGIDBody /> */}
                  {/* <AdComponent2 /> */}
                </Grid>
              )}
            </Grid>
          );
        })}
        {isPaginating &&
          Array.from(isMobile ? new Array(1) : new Array(3)).map(
            (el, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  key={"paginate-loading" + index}
                >
                  <LoadingCard />
                </Grid>
              );
            }
          )}
      </Grid>
      {/* <MGIDBody /> */}
      {/* <AdComponent2 /> */}
      {isLastArticlePage && <Footer />}
    </>
  );
};

export default ArticleList;
