import * as React from "react";
import {
  AppBarSc,
  LogoContainer,
  NavOptionContainer,
  MenuIconButton,
} from "./style.js";
import Logo from "../../assets/images/logo.png";
import menu from "../../assets/icons/menu.jpg";
import { Box, IconButton, Skeleton } from "@mui/material";
import NavbarButton from "./NavbarButtons/index.js";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import { pxToRem } from "../../utils/pxToRem.js";
import SwipeableTemporaryDrawer from "./SideBar/index.js";

const NavBar = ({ storyMode }) => {
  const location = useLocation();
  const [isMenuDisplay, setIsMenuDisplay] = React.useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [navbarOptions, setNavbarOptions] = React.useState([]);
  const [isNavLoading, setIsNavLoading] = React.useState(true);
  const getHomeItems = async () => {
    setIsNavLoading(true);
    try {
      let response = await axios.get(
        "https://api.vistory.in/vistory/api/v1/home/getHomeItems",
        {
          headers: {
            "DEVICE-TYPE": "Web",
            VER: "1",
          },
        }
      );
      response.data.responseObject[0].navBar.shift();
      setNavbarOptions([...response.data.responseObject[0].navBar]);
      setIsNavLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    getHomeItems();
  }, []);

  React.useEffect(() => {
    if (location.pathname.includes('details')) {
      setIsMenuDisplay(false);
    } else {
      setIsMenuDisplay(true);
    }
  }, [location]);

  let navigate = useNavigate();
  const redirectToHome = () => {
    navigate("/");
  };
  const OptionContainer = styled("div")(({ theme }) => ({
    display: "flex",
    overflow: "scroll",
    justifyContent: "flex-start",
    margin: "0",

    padding: `${pxToRem(4)} ${pxToRem(1)} 0`,
    [theme.breakpoints.down("md")]: {
      overflowX: "auto",
    },
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent",
    },
  }));
  const [sideBar, setSideBar] = React.useState({
    right: false,
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setSideBar({ ...sideBar, ["right"]: open });
  };

  return (
    <AppBarSc position="static" display={storyMode ? "none" : "flex"}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        {!isNavLoading && (
          <>
            <LogoContainer>
              <img
                src={Logo}
                alt="Logo"
                width={isMobile ? "100px" : "150px"}
                onClick={() => redirectToHome()}
              />
              <MenuIconButton onClick={toggleDrawer(true)}>
                <IconButton sx={{ paddingLeft: "0" }}>
                  <img
                    src={menu}
                    alt="menu-icon"
                    width={isMobile ? "25px" : "20px"}
                  />
                </IconButton>
              </MenuIconButton>
            </LogoContainer>
            <SwipeableTemporaryDrawer
              state={sideBar}
              toggleDrawer={toggleDrawer}
              navbarOptions={navbarOptions}
            />
            {isMenuDisplay && <OptionContainer>
              <div
                style={{
                  alignItems: "center",
                  overflow: "hidden",
                  marginLeft: isMobile ? "0" : pxToRem(100),
                  marginTop: isMobile ? "0" : pxToRem(10),
                }}
              >
                <NavbarButton name={"Home"} index={1} />
              </div>
              <NavOptionContainer>
                {navbarOptions.map((element) => (
                  <NavbarButton name={element.name} key={element.name} />
                ))}
              </NavOptionContainer>
            </OptionContainer>}
          </>
        )}
        {isNavLoading && (
          <>
            <LogoContainer>
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={pxToRem(100)}
                height={25}
              />
              <MenuIconButton>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={pxToRem(50)}
                  height={25}
                />
              </MenuIconButton>
            </LogoContainer>
            {!isMobile ? <div style={{ width: pxToRem(120) }}></div> : ""}
            {isMenuDisplay &&
              <OptionContainer>
                <NavOptionContainer>
                  {Array.from(new Array(6)).map((el, index) => {
                    return (
                      <Skeleton
                        key={index}
                        animation="wave"
                        variant="rectangular"
                        width={pxToRem(100)}
                        height={30}
                        style={{ marginRight: pxToRem(10) }}
                      />
                    );
                  })}
                </NavOptionContainer>
              </OptionContainer>
            }
          </>
        )}
      </Box>
    </AppBarSc>
  );
};
export default NavBar;
