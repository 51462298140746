import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { pxToRem } from "../../../utils/pxToRem";
import { CardSc } from "../../Card/style";
function LoadingCard() {
  return (
    <CardSc>
      <Skeleton variant="rectangular" height={250} />
      <Box sx={{ padding: pxToRem(10) }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: pxToRem(10),
          }}
        >
          <Skeleton width="20%" />
          <Skeleton width="10%" />
        </Box>
        <Box>
          <Skeleton />
          <Skeleton width="60%" />
          <Skeleton />
          <Skeleton />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: pxToRem(10),
          }}
        >
          <Skeleton width="20%" />
          <Skeleton width="20%" />
        </Box>
      </Box>
    </CardSc>
  );
}

export default LoadingCard;
