import React, { useEffect, useRef } from "react";
import { NavbarButtonSc } from "./style";
import { useMatch, useNavigate, useLocation } from "react-router-dom";
import { pxToRem } from "../../../utils/pxToRem";

function NavbarButton({ name, myStyle }) {
  const selectedName = useMatch("/" + name);
  const selectedDefault = useMatch("/");
  const { state, pathname } = useLocation();
  const category = state ? state.category : "";
  const refVal = useRef(null);
  const pathNameCategory = pathname.split("/")[1];
  const isActive = selectedName
    ? true
    : selectedDefault && name === "Home"
    ? true
    : name === category || name === pathNameCategory
    ? true
    : false;
  const selectedStyle = {
    borderBottom: "2px solid #F3854D",
    color: "#F3854D",
    marginRight: pxToRem(20),
  };
  let navigate = useNavigate();
  const clickHandler = () => {
    navigate(`/${name}`);
  };
  useEffect(() => {
    if (refVal.current) {
      refVal.current.scrollIntoView();
    }
  }, [isActive]);
  return (
    <>
      <NavbarButtonSc
        ref={isActive ? refVal : null}
        key={name}
        onClick={() => clickHandler()}
        style={myStyle}
      >
        <span style={isActive ? selectedStyle : { marginRight: pxToRem(20) }}>
          {name}
        </span>
      </NavbarButtonSc>
    </>
  );
}

export default NavbarButton;
