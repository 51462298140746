import { styled } from "@mui/material/styles";

export const DataContent = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    p: {
      textAlign: "left",
      fontWeight: "400",
    },
    img: {
      width: "100% !important",
      height: "auto !important",
    },
    
  },
}));
