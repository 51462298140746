import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LayoutContainer from "./container/LayoutContainer";
import ArticleList from "./pages/ArticleList";
import ArticleDetail from "./pages/ArticleDetail";
import AllStories from "./pages/AllStories";
import DetailTab from "./pages/DetailTab";
import MinusOne from "./pages/ArticleDetail/MinusOne";
function App() {
  return (
    <BrowserRouter>
      <LayoutContainer>
        <Routes>
          <Route path="/" element={<ArticleList />} />
          <Route path="/minus-one" element={<ArticleList />} />
          <Route path="/minus-one/:id/:title" element={<MinusOne />} />
          <Route path="/all-stories/:id" element={<AllStories />} />
          <Route path="/:category" element={<ArticleList />} />
          <Route path="/:category/detail/:id" element={<ArticleDetail />} />
          <Route path="/:category/details/:id" element={<DetailTab />} />
        </Routes>
      </LayoutContainer>
    </BrowserRouter>
  );
}

export default App;
